import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, filter } from 'lodash/fp';
import { Dialog, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import TagHeader from '../common/TagHeader';
import OrganisationList from './OrganisationList';
import ProgrammeList from './ProgrammeList';
import type { IMission } from '../../services/Missions';
import { useMission } from '../../services/Missions';
import type { Programme, Organisation } from 'api/administration/service';
import s from './index.module.scss';
import SearchInput from '_atoms/SearchInput/SearchInput';
import Fuse from 'fuse.js';

interface IProps {
  user: any;
  isOpen: boolean;
  onClose: () => void;
  organisationApi: any;
  organisationsList: Organisation[];
  programmesList: Programme[];
  missionsList: IMission[];
  onMissionClick?: () => void;
}

const OrganisationConfiguration = (props: IProps) => {
  const { currentMission } = useMission();
  const [chosenOrgId, setChosenOrgId] = useState<number>();
  const [chosenProgramme, setChosenProgramme] = useState<Programme>();

  const [autoOpenProgramme, setAutoOpenProgramme] = useState<boolean>(false);

  const {
    isOpen,
    onClose,
    missionsList: unfilteredMissionList,
    programmesList: unfilteredProgrammeList,
    organisationsList: unfilteredOrganisationsList,
    organisationApi,
  } = props;

  const [missionsList, setMissionsList] = useState<IMission[]>(
    unfilteredMissionList
  );
  const [programmesList, setProgrammesList] = useState<Programme[]>(
    unfilteredProgrammeList
  );
  const [organisationsList, setOrganisationsList] = useState<Organisation[]>(
    unfilteredOrganisationsList
  );

  const fuse = useMemo(
    () =>
      new Fuse([...unfilteredMissionList, ...unfilteredProgrammeList], {
        keys: ['name'],
        minMatchCharLength: 2,
        threshold: 0.2,
      }),
    [unfilteredMissionList, unfilteredProgrammeList]
  );

  const getFilteredResults = (val: string) => {
    return fuse.search<IMission | Programme>(val).reduce(
      (acc, curr) => {
        const res =
          (curr as unknown as { item: IMission | Programme }).item ??
          (curr as IMission | Programme);

        if ((res as IMission).mission) {
          return {
            ...acc,
            missions: [...(acc.missions ?? []), res as IMission],
          };
        }
        return {
          ...acc,
          programmes: [...(acc.programmes ?? []), res as Programme],
        };
      },
      { missions: [], programmes: [] } as {
        missions: IMission[];
        programmes: Programme[];
      }
    );
  };

  const handleFoundMissions = (results: {
    missions: IMission[];
    programmes: Programme[];
  }) => {
    if (results.missions.length > 0) {
      const programmesMatchingMissions = unfilteredProgrammeList.filter(
        (prog) =>
          results.missions.some((mission) => mission.programme === prog.id)
      );

      const organisationsMatchingProgrammes =
        unfilteredOrganisationsList.filter((org) =>
          programmesMatchingMissions.some(
            (prog) => prog.organisationId === org.id
          )
        );

      // If we have found missions, we set it as the new list of missions
      setMissionsList(results.missions);
      // If we have a list of missions, we set the programmes matching those missions as the new list of programmes
      setProgrammesList(programmesMatchingMissions);
      // If we have a list of programmes, we set the organisations matching those programmes as the new list of organisations
      setOrganisationsList(organisationsMatchingProgrammes);

      // First organisation will be the closest match, so we select it by default
      setChosenOrgId(organisationsMatchingProgrammes[0].id);
    }
  };

  const handleFoundProgrammes = (results: {
    missions: IMission[];
    programmes: Programme[];
  }) => {
    // Since missions are the lowest in the hierarchy, if we found any missions no need to go further, since we already have the programmes from those missions
    if (results.missions.length > 0) {
      return;
    }

    // In case we find no missions, we're setting the programmes to the ones we found with all their missions
    if (results.programmes.length > 0) {
      const missionsMatchingProgrammes = unfilteredMissionList.filter((m) =>
        results.programmes.some((prog) => prog.id === m.programme)
      );

      const organisationsMatchingProgrammes =
        unfilteredOrganisationsList.filter((org) =>
          results.programmes.some((prog) => prog.organisationId === org.id)
        );

      setMissionsList(missionsMatchingProgrammes);
      setProgrammesList(results.programmes);
      setOrganisationsList(organisationsMatchingProgrammes);

      setChosenOrgId(organisationsMatchingProgrammes[0].id);
    }
  };

  const handleNoResultsFound = useCallback(
    (results: { missions: IMission[]; programmes: Programme[] }) => {
      if (results.missions.length === 0 && results.programmes.length === 0) {
        setMissionsList(unfilteredMissionList);
        setProgrammesList(unfilteredProgrammeList);
        setOrganisationsList(unfilteredOrganisationsList);
      }
    },
    [
      unfilteredMissionList,
      unfilteredOrganisationsList,
      unfilteredProgrammeList,
    ]
  );

  useEffect(() => {
    setMissionsList(unfilteredMissionList);
    setProgrammesList(unfilteredProgrammeList);
    setOrganisationsList(unfilteredOrganisationsList);
  }, [
    unfilteredMissionList,
    unfilteredOrganisationsList,
    unfilteredProgrammeList,
    isOpen,
  ]);

  useEffect(() => {
    if (currentMission) {
      const prog = find(['id', currentMission.programme], programmesList);

      if (!prog) {
        return;
      }

      setChosenOrgId(prog.organisationId);
      setChosenProgramme(prog);
    }
  }, [currentMission, missionsList, programmesList, organisationsList]);

  return (
    <Dialog
      usePortal
      canOutsideClickClose
      className={s.dialogBody}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={s.formFactorContainer}>
        <div className={s.organizationWrapper}>
          <TagHeader
            icon={IconNames.OFFICE}
            intent={Intent.PRIMARY}
            title="Organisation"
          />
          <OrganisationList
            chosenOrg={chosenOrgId}
            setChosenOrg={setChosenOrgId}
            organisations={organisationsList}
            saveOrganisation={organisationApi.postOrganisations}
          />
        </div>
        <div className={s.programmeWrapper}>
          <TagHeader
            icon={IconNames.SATELLITE}
            intent={Intent.PRIMARY}
            title={
              <div className="flex items-center justify-between">
                <span>Missions</span>
                <SearchInput
                  size="small"
                  fullWidth={false}
                  onChange={(val) => {
                    const reducedResults = getFilteredResults(val);
                    setAutoOpenProgramme(
                      reducedResults.missions.length !== 0 ||
                        reducedResults.programmes.length !== 0
                    );
                    handleNoResultsFound(reducedResults);
                    handleFoundMissions(reducedResults);
                    handleFoundProgrammes(reducedResults);
                  }}
                />
              </div>
            }
          />
          <ProgrammeList
            programmes={filter(['organisationId', chosenOrgId], programmesList)}
            missions={missionsList}
            chosenOrg={chosenOrgId}
            chosenProgramme={chosenProgramme}
            saveProgramme={organisationApi.postProgrammes}
            saveMission={organisationApi.postMissions}
            autoOpenProgramme={autoOpenProgramme}
            onMissionClick={props.onMissionClick}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default OrganisationConfiguration;
