import SecondaryButton from '_molecules/Button/SecondaryButton';
import type {
  ActivityOnboardProcessingParameter,
  ActivityParameters,
} from 'api/activities/types';
import type { SwathControlData } from 'api/tasking/helpers';
import type { IProperty } from 'pages/ops/RTI/Operate/components/CommandArguments';
import CommandGroupArgument from 'pages/ops/RTI/Operate/components/CommandArguments/CommandGroupArgument';
import { assoc } from 'lodash/fp';
import { Dialog, Tab, TabList, TabPanel, Tabs } from 'opencosmos-ui';
import { useEffect, useRef, useState, useMemo } from 'react';
import { Item } from 'react-stately';
import { showErrorMessage } from 'utils/common/CommonUtils';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { fileDownload } from 'utils/fileDownload';

interface AdvancedTaskingProps {
  disabled?: boolean;
  schema: IProperty[];
  handleOptionsChange: (controlData: {}) => void;
  swathControlData: SwathControlData | undefined;
  defaultValues?: ActivityParameters;
  buttonText?: string;
  isRequestTypeAutomated?: boolean;
}

const AdvancedSwathControl = ({
  schema,
  handleOptionsChange,
  swathControlData,
  defaultValues,
  disabled,
  buttonText,
  isRequestTypeAutomated,
}: AdvancedTaskingProps) => {
  const [openAdvancedOptions, setOpenAdvancedOptions] =
    useState<boolean>(false);

  const [advancedOptions, setAdvancedOptions] = useState({});

  const { translate } = useLocalisation();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleAdvancedTaskingParamsChange = (path: string, value: unknown) => {
    const advancedParams = assoc(path, value, advancedOptions);
    if (advancedOptions) {
      setAdvancedOptions({ ...advancedOptions, ...advancedParams });
      return;
    }
    setAdvancedOptions({ ...advancedParams } as ActivityParameters);
  };

  const isOnboardProcessingFileContentsEmpty = useMemo(() => {
    const files = (advancedOptions as ActivityParameters)?.onboard_processing
      ?.files as ActivityOnboardProcessingParameter[];
    if (!files) {
      return;
    }
    if (!files.every((file) => file?.name?.length || file?.raw?.length)) {
      return;
    }
    return !files.every((obj) => obj.name && obj.raw);
  }, [advancedOptions]);

  useEffect(() => {
    if (openAdvancedOptions) {
      setAdvancedOptions({ ...defaultValues, ...swathControlData?.parameters });
    }
  }, [swathControlData?.parameters, openAdvancedOptions, defaultValues]);

  return (
    <>
      <SecondaryButton
        text={buttonText ?? 'Advanced options'}
        onPress={() => setOpenAdvancedOptions(true)}
        fill
        disabled={disabled}
      />

      <Dialog
        buttons={[
          {
            shown: true,
            onPress: () => {
              if (isOnboardProcessingFileContentsEmpty) {
                showErrorMessage(
                  `Name or file content in onboard processing cannot be empty`
                );
                return;
              }
              handleOptionsChange(advancedOptions);
            },
            text: translate('datacosmos.buttons.save'),
            keepDialogOpenOnPress:
              isOnboardProcessingFileContentsEmpty ?? false,
          },
          {
            shown: true,
            onPress: () => {
              setAdvancedOptions({});
              if (defaultValues) {
                handleOptionsChange(defaultValues);
              }
            },
            text: translate('datacosmos.buttons.resetAndClose'),
          },
          {
            shown: true,
            onPress: async () => {
              const paramsFileUint8 = (await new Blob(
                [JSON.stringify(advancedOptions)],
                {
                  type: 'application/json',
                }
              ).arrayBuffer()) as Uint8Array;

              fileDownload(
                paramsFileUint8,
                'tasking_parameters.json',
                'application/json'
              );
            },
            text: translate('datacosmos.buttons.downloadParameters'),
            keepDialogOpenOnPress: true,
          },
          {
            shown: true,
            onPress: () => {
              fileInputRef.current?.click();
            },
            text: translate('datacosmos.buttons.uploadParameters'),
            keepDialogOpenOnPress: true,
          },
        ]}
        isOpen={openAdvancedOptions}
        onClose={() => {
          setAdvancedOptions({});
          setOpenAdvancedOptions(false);
        }}
        title="Advanced tasking options"
        hideCancelButton
        showButtonsInFooter
      >
        <>
          <Tabs>
            <TabList className={'flex justify-between'}>
              {schema.map((property: IProperty) => (
                <Tab
                  className={'w-full'}
                  id={property.optionalFields.title ?? property.path}
                  key={property.optionalFields.title ?? property.path}
                >
                  <div className="text-xs dark:text-neutral">
                    {property.optionalFields.title ?? property.path}
                  </div>
                </Tab>
              ))}
            </TabList>
            {schema.map((property: IProperty) => (
              <TabPanel
                key={property.optionalFields.title ?? property.path}
                id={property.optionalFields.title ?? property.path}
              >
                <CommandGroupArgument
                  key={property.path}
                  property={property}
                  value={advancedOptions}
                  onBlur={() => {}}
                  onChange={(value, path) =>
                    handleAdvancedTaskingParamsChange(path, value)
                  }
                  enableAllFields={isRequestTypeAutomated}
                />
              </TabPanel>
            ))}
          </Tabs>
        </>
      </Dialog>
      <input
        type={'file'}
        ref={fileInputRef}
        className="hidden"
        onChange={(ev) => {
          const file = ev.target.files?.[0];
          if (!file) {
            return;
          }
          const reader = new FileReader();
          reader.readAsText(file);
          reader.onloadend = (e) => {
            const content = e.target?.result;
            if (!content) {
              return;
            }
            const params = JSON.parse(content as string) as {};
            setAdvancedOptions(params);
            handleOptionsChange(params);
          };

          fileInputRef.current?.value && (fileInputRef.current.value = '');
        }}
        accept=".json"
      />
    </>
  );
};

export default AdvancedSwathControl;
