import { ActivityStatusList, CloudStatusList } from 'api/activities/types';
import type {
  ActivityStatus,
  Activity,
  ActivityWithRequestNote,
  CloudStatus,
} from 'api/activities/types';
import classNames from 'classnames';
import type { CellContext } from 'opencosmos-ui';
import { Table } from 'opencosmos-ui';
import React, { useCallback, useEffect } from 'react';
import InputCellWithPopover from './InputCellWithPopover';
import { useActivities } from '../../context/ActivitiesProvider';
import SelectCell from './SelectCell';
import NumberInputCell from './NumberInputCell';

type Props = {
  activities: ActivityWithRequestNote[];
  onActivitySelect: (activity: Activity | undefined) => void;
  expanded?: boolean;
  hiddenColumns?: string[];
};

// Columns that should not be displayed in the table at all
export const EXCLUDED_COLUMNS = ['geojson'];
// Columns that should be hidden by default
export const DEFAULT_HIDDEN_COLUMNS = [
  'parameters.platform.roll_angle',
  'parameters.imager.mode',
  'parameters.imager.name',
  'parameters.imager.encoding',
  'parameters.imager.band_setup',
  'parameters.imager.line_period_us',
  'parameters.imager.number_of_lines',
  'parameters.imager.exposure_time_us',
  'parameters.imager.acquisition_padded',
  'parameters.imager.compression_factor',
  'parameters.imager.acquisition_end_date',
  'parameters.imager.acquisition_start_date',
  'parameters.imager.predicted_file_size_gb',
  'parameters.physical.target.ephemeris.type',
  'parameters.physical.target.ephemeris.attitude_law',
  'parameters.physical.target.ephemeris.data.epoch',
  'parameters.physical.target.ephemeris.data.line1',
  'parameters.physical.target.ephemeris.data.line2',
  'parameters.physical.target.midpoint.date',
  'parameters.physical.target.midpoint.sza_deg',
  'parameters.physical.target.midpoint.oza_deg',
  'parameters.physical.target.midpoint.sat_body_pos_m',
  'parameters.physical.target.midpoint.sat_body_vel_ms',
  'parameters.physical.target.midpoint.slant_range_m',
  'parameters.physical.target.midpoint.ground_velocity_ms',
  'parameters.physical.latest.ephemeris.type',
  'parameters.physical.latest.ephemeris.attitude_law',
  'parameters.physical.latest.ephemeris.data.epoch',
  'parameters.physical.latest.ephemeris.data.line1',
  'parameters.physical.latest.ephemeris.data.line2',
  'parameters.physical.latest.midpoint.date',
  'parameters.physical.latest.midpoint.sza_deg',
  'parameters.physical.latest.midpoint.oza_deg',
  'parameters.physical.latest.midpoint.sat_body_pos_m',
  'parameters.physical.latest.midpoint.sat_body_vel_ms',
  'parameters.physical.latest.midpoint.slant_range_m',
  'parameters.physical.latest.midpoint.ground_velocity_ms',
  'parameters.metrics.cloud_status',
];

const SchedulingTable = ({
  activities,
  onActivitySelect,
  expanded,
  hiddenColumns,
}: Props) => {
  const { updateActivity, updatedActivitiesMap } = useActivities();

  const getSelectIntent = (
    status: ActivityStatus
  ): 'none' | 'success' | 'warning' => {
    switch (status) {
      case 'COMPLETED':
        return 'success';
      case 'CANCELLED':
      case 'FAILED':
      case 'EXPIRED':
        return 'warning';
      default:
        return 'none';
    }
  };

  const renderStatusSelector = (
    ctx: CellContext<ActivityWithRequestNote, unknown>
  ) => {
    const updatedActivityStatus =
      updatedActivitiesMap?.[ctx.row.original.id]?.status ??
      (ctx.getValue() as ActivityStatus);

    return (
      <SelectCell
        selectedItemProps={{
          className: classNames({
            'bg-neutral-300 dark:bg-neutral-600':
              updatedActivityStatus === 'PENDING' ||
              updatedActivityStatus === 'APPROVED',
            'bg-neutral-500 dark:bg-neutral-800':
              updatedActivityStatus === 'CONFIRMED' ||
              updatedActivityStatus === 'SCHEDULED' ||
              updatedActivityStatus === 'PROCESSING',
            'bg-success': updatedActivityStatus === 'COMPLETED',
            'bg-warning':
              updatedActivityStatus === 'CANCELLED' ||
              updatedActivityStatus === 'FAILED',
          }),
          intent: getSelectIntent(updatedActivityStatus),
        }}
        items={[...ActivityStatusList]}
        selectedKey={updatedActivityStatus}
        onSelectionChange={(activityStatus) => {
          updateActivity(ctx.row.original.id, {
            status: activityStatus as ActivityStatus,
          });
        }}
      />
    );
  };

  const renderCloudStatus = useCallback(
    (ctx: CellContext<ActivityWithRequestNote, unknown>) => {
      const updatedCloudStatus =
        updatedActivitiesMap?.[ctx.row.original.id]?.parameters?.metrics
          ?.cloud_status ?? (ctx.getValue() as CloudStatus);

      return (
        <SelectCell
          items={[...CloudStatusList]}
          selectedKey={updatedCloudStatus}
          onSelectionChange={(cloudStatus) => {
            updateActivity(ctx.row.original.id, {
              parameters: {
                metrics: {
                  cloud_status: cloudStatus as CloudStatus,
                },
              },
            });
          }}
        />
      );
    },
    [updateActivity, updatedActivitiesMap]
  );

  const renderOperatorNotes = (
    ctx: CellContext<ActivityWithRequestNote, unknown>
  ) => {
    const updatedOperatorNotes =
      updatedActivitiesMap?.[ctx.row.original.id]?.operator_notes ??
      ctx.row.original.operator_notes;
    return (
      <InputCellWithPopover
        inputFor="operator_notes"
        value={updatedOperatorNotes}
        activityId={ctx.row.original.id}
        updateActivity={updateActivity}
      />
    );
  };

  const renderRequestNote = (
    ctx: CellContext<ActivityWithRequestNote, unknown>
  ) => {
    const updatedRequestNote = ctx.row.original.request_note;
    return (
      <InputCellWithPopover
        value={updatedRequestNote}
        activityId={ctx.row.original.id}
        updateActivity={updateActivity}
      />
    );
  };

  const renderSessionId = (
    ctx: CellContext<ActivityWithRequestNote, unknown>
  ) => {
    return (
      <NumberInputCell
        activityId={ctx.row.original.id}
        updateActivity={updateActivity}
        value={
          updatedActivitiesMap?.[ctx.row.original.id]?.parameters?.session
            ?.session_id ?? (ctx.getValue() as number)
        }
      />
    );
  };

  // Hack to prevent scrolling on the html element when the table is rendered
  // This is due to *something* going on with the <Select/> component
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = '';
    };
  }, []);

  return (
    <div
      className={classNames('overflow-x-auto', {
        'w-[47.5vw]': !expanded,
        'w-full': expanded,
      })}
    >
      <Table
        data={activities}
        hiddenColumns={hiddenColumns}
        cellRenderer={(ctx, header) => {
          if (header === 'parameters.session.session_id') {
            return renderSessionId(ctx);
          }

          if (header === 'status') {
            return renderStatusSelector(ctx);
          }

          if (header === 'parameters.metrics.cloud_status') {
            return renderCloudStatus(ctx);
          }

          if (header === 'operator_notes') {
            return renderOperatorNotes(ctx);
          }

          if (header === 'request_note') {
            return renderRequestNote(ctx);
          }

          return <span className="p-1">{String(ctx.getValue())}</span>;
        }}
        enableRowSelection={true}
        enableMultiRowSelection={false}
        showFullHeaderTitle={false}
        excludeDataKeys={EXCLUDED_COLUMNS}
        onRowSelect={(activity) => {
          // If the activity is an array, do nothing for now
          if (Array.isArray(activity)) {
            return;
          }

          onActivitySelect(activity);
        }}
      />
    </div>
  );
};

export default SchedulingTable;
