import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';
import {
  ARMED_COMMAND_PREVIEW_CLOSE,
  ARMED_COMMAND_PREVIEW_OPEN,
  CLEAR_COMMAND_CUSTOM_NAME,
  CLEAR_CURRENT_PAYLOAD,
  COMMAND_CHANGE_DONE,
  COPY_COMMAND_TO_EDITOR,
  FINISH_AVAILABLE_COMMANDS,
  FINISH_HISTORY_COMMAND_LIST,
  HISTORY_COMMAND_CLOSE,
  HISTORY_COMMAND_OPEN,
  HISTORY_FOCUS_LAST_SENT_COMMAND,
  HISTORY_SET_CURRENT_RESPONSE,
  HISTORY_UPDATE_COMPLETED_STATUS,
  LAST_HISTORY_COMMAND_REACHED,
  PAST_COMMAND_FROM_CLIPBOARD,
  PILE_COMMAND_REMOVE,
  PILE_ITEM_CLOSE,
  PILE_ITEM_OPEN,
  REORDER_COMMAND_PILE,
  REQUEST_AVAILABLE_COMMANDS,
  REQUEST_HISTORY_COMMAND_LIST,
  SAVE_CLOUD_STORAGE_PATH,
  SAVE_SATELLITE_STORAGE_PATH,
  SELECTED_PILE_ITEM,
  SELECT_COMMAND_FROM_SUGGESTION,
  SELECT_SIDE_PANEL_COMMAND,
  SET_COMMAND_CUSTOM_NAME,
  SET_COMMAND_PAYLOAD,
  SET_CURRENTLY_EDITING_COMMAND_PAYLOAD,
  SET_EXPLORING_STORAGE_NAME,
  SET_HISTORY_COMMAND_TYPE,
  SET_PROCEDURE_NAME,
  SET_SEND_COMMAND_DISABLED,
  SET_TERMINAL_WORKSPACE,
  TOGGLE_COMMAND_HISTORY_COLLAPSE,
  TOGGLE_LEFT_PANEL,
  TOGGLE_RIGHT_PANEL,
} from 'constants/ops/rti/oparate/actionTypes';
import type {
  IOperateArmedCommandPreviewClose,
  IOperateArmedCommandPreviewOpen,
  IOperateClearCommandCustomName,
  IOperateClearCurrentPayload,
  IOperateCommandChangeDone,
  IOperateCopeCommandToEditor,
  IOperateFinishAvailableCommands,
  IOperateFinishHistoryCommandList,
  IOperateHistoryCommandClose,
  IOperateHistoryCommandOpen,
  IOperateHistorySetCurrentResponse,
  IOperateLastHistoryCommandReached,
  IOperatePastCommandFromClipboard,
  IOperatePileCommandRemove,
  IOperatePileItemClose,
  IOperatePileItemOpen,
  IOperatePileItemSelection,
  IOperateReorderCommandPile,
  IOperateRequestAvailableCommands,
  IOperateRequestHistoryCommandList,
  IOperateSaveCloudStoragePath,
  IOperateSaveSatelliteStoragePath,
  IOperateSelectCommandFromSuggestion,
  IOperateSelectSidePanelCommand,
  IOperateSetCommandCustomName,
  IOperateSetCommandPayload,
  IOperateSetCurrentlyEditingCommandPayload,
  IOperateSetExploringStorageName,
  IOperateSetHistoryCommandType,
  IOperateSetProcedureName,
  IOperateSetSendCommandDisabled,
  IOperateSetTerminalWorkSpace,
  IOperateToggleCommandHistoryCollapse,
  IOperateToggleLeftPanel,
  IOperateToggleRightPanel,
  IOperateHistoryUpdateCompleteStatus,
  IHistoryFocusLastSentCommand,
} from 'constants/ops/rti/oparate/actionTypes';

import type {
  ICommandHistory,
  CommandHistoryPayload,
} from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import type { CommandDefinition } from 'api/telecommands/types';

export const setTerminalWorkSpace = (
  armedCommand: ICommandPileItem | undefined,
  commandPileWorkingSpace: ICommandPileItem[],
  frozenPile: boolean
): IOperateSetTerminalWorkSpace => {
  return {
    type: SET_TERMINAL_WORKSPACE,
    payload: {
      armedCommand,
      commandPileWorkingSpace,
      frozenPile,
    },
  };
};

export const reorderCommandPile = (
  fromIndex: number,
  toIndex: number,
  selectedElements: ICommandPileItem[] | ICommandPileItem | undefined
): IOperateReorderCommandPile => {
  return {
    type: REORDER_COMMAND_PILE,
    payload: {
      fromIndex,
      toIndex,
      selectedElements,
    },
  };
};

export const selectSidePanelCommand = (
  command: CommandDefinition
): IOperateSelectSidePanelCommand => {
  return {
    type: SELECT_SIDE_PANEL_COMMAND,
    payload: {
      command,
    },
  };
};

export const clearCurrentPayload = (): IOperateClearCurrentPayload => {
  return { type: CLEAR_CURRENT_PAYLOAD };
};

export const setCommandPayload = (
  commandPayload: CommandHistoryPayload
): IOperateSetCommandPayload => {
  return {
    type: SET_COMMAND_PAYLOAD,
    payload: {
      commandPayload,
    },
  };
};

export const setCurrentlyEditingCommandPayload = (
  commandPayload: CommandHistoryPayload
): IOperateSetCurrentlyEditingCommandPayload => ({
  type: SET_CURRENTLY_EDITING_COMMAND_PAYLOAD,
  payload: {
    currentylEditingCommandPayload: commandPayload,
  },
});

export const clearCommandCustomName = (): IOperateClearCommandCustomName => {
  return { type: CLEAR_COMMAND_CUSTOM_NAME };
};

export const setCommandCustomName = (
  commandCustomName: string
): IOperateSetCommandCustomName => {
  return {
    type: SET_COMMAND_CUSTOM_NAME,
    payload: {
      commandCustomName,
    },
  };
};

export const armedCommandPreviewOpen = (
  armedCommand: ICommandPileItem,
  command: CommandDefinition
): IOperateArmedCommandPreviewOpen => {
  return {
    type: ARMED_COMMAND_PREVIEW_OPEN,
    payload: {
      command,
      armedCommand,
    },
  };
};

export const copyCommandToEditor = (
  selectedCommand: CommandDefinition,
  commandPayload: CommandHistoryPayload,
  commandCustomName: string | undefined
): IOperateCopeCommandToEditor => {
  return {
    type: COPY_COMMAND_TO_EDITOR,
    payload: {
      selectedCommand,
      commandPayload,
      commandCustomName,
    },
  };
};

export const armedCommandPreviewClose =
  (): IOperateArmedCommandPreviewClose => ({
    type: ARMED_COMMAND_PREVIEW_CLOSE,
  });

export const commandChangeDone = (): IOperateCommandChangeDone => ({
  type: COMMAND_CHANGE_DONE,
});

export const setSendCommandDisabled = (
  disabled: boolean
): IOperateSetSendCommandDisabled => ({
  type: SET_SEND_COMMAND_DISABLED,
  payload: {
    disabled,
  },
});

export const setExploringStorageName = (
  storageName: string
): IOperateSetExploringStorageName => ({
  type: SET_EXPLORING_STORAGE_NAME,
  payload: {
    storageName,
  },
});

export const pileCommandRemove = (): IOperatePileCommandRemove => ({
  type: PILE_COMMAND_REMOVE,
});

export const pileItemOpen = (
  pileItem: ICommandPileItem,
  selectedCommand: CommandDefinition
): IOperatePileItemOpen => ({
  type: PILE_ITEM_OPEN,
  payload: {
    pileItem,
    selectedCommand,
  },
});

export const pileItemSelection = (
  pileItemsSelected: ICommandPileItem[] | undefined
): IOperatePileItemSelection => ({
  type: SELECTED_PILE_ITEM,
  payload: {
    pileItemsSelected,
  },
});

export const pileItemClose = (): IOperatePileItemClose => ({
  type: PILE_ITEM_CLOSE,
});

export const historyCommandOpen = (
  history: ICommandHistory,
  command: CommandDefinition | undefined
): IOperateHistoryCommandOpen => {
  return {
    type: HISTORY_COMMAND_OPEN,
    payload: {
      history,
      command,
    },
  };
};

export const historyUpdateCompletedStatus = (
  history: ICommandHistory
): IOperateHistoryUpdateCompleteStatus => {
  return {
    type: HISTORY_UPDATE_COMPLETED_STATUS,
    payload: {
      history,
    },
  };
};

export const historyCommandClose = (): IOperateHistoryCommandClose => ({
  type: HISTORY_COMMAND_CLOSE,
});

export const requestHistoryCommandList =
  (): IOperateRequestHistoryCommandList => ({
    type: REQUEST_HISTORY_COMMAND_LIST,
  });

export const finishHistoryCommandList =
  (): IOperateFinishHistoryCommandList => ({
    type: FINISH_HISTORY_COMMAND_LIST,
  });

export const requestAvailableCommands =
  (): IOperateRequestAvailableCommands => ({
    type: REQUEST_AVAILABLE_COMMANDS,
  });

export const finishRequestingAvailableCommands =
  (): IOperateFinishAvailableCommands => ({
    type: FINISH_AVAILABLE_COMMANDS,
  });

export const setHistoryCommandType = (
  type: string
): IOperateSetHistoryCommandType => ({
  type: SET_HISTORY_COMMAND_TYPE,
  payload: {
    type,
  },
});

export const lastHistoryCommandReached =
  (): IOperateLastHistoryCommandReached => ({
    type: LAST_HISTORY_COMMAND_REACHED,
  });

export const setProcedureName = (
  procedureName: string
): IOperateSetProcedureName => ({
  type: SET_PROCEDURE_NAME,
  payload: {
    procedureName,
  },
});

export const historySetCurrentCommand = (
  history: ICommandHistory
): IOperateHistorySetCurrentResponse => ({
  type: HISTORY_SET_CURRENT_RESPONSE,
  payload: {
    history,
  },
});

export const selectCommandFromSuggestion = (
  selectedCommand: CommandDefinition
): IOperateSelectCommandFromSuggestion => {
  return {
    type: SELECT_COMMAND_FROM_SUGGESTION,
    payload: {
      selectedCommand,
    },
  };
};

export const pastCommandFromClipboard = (
  selectedCommand: CommandDefinition,
  commandPayload: CommandHistoryPayload
): IOperatePastCommandFromClipboard => {
  return {
    type: PAST_COMMAND_FROM_CLIPBOARD,
    payload: {
      selectedCommand,
      commandPayload,
    },
  };
};

export const saveCloudStoragePath = (
  path: string
): IOperateSaveCloudStoragePath => ({
  type: SAVE_CLOUD_STORAGE_PATH,
  payload: { path },
});

export const saveSatelliteStoragePath = (
  path: string
): IOperateSaveSatelliteStoragePath => ({
  type: SAVE_SATELLITE_STORAGE_PATH,
  payload: { path },
});

export const hideLeftPanel = (): IOperateToggleLeftPanel => {
  return {
    type: TOGGLE_LEFT_PANEL,
    payload: {
      isLeftPanelHidden: true,
    },
  };
};
export const showLeftPanel = (): IOperateToggleLeftPanel => {
  return {
    type: TOGGLE_LEFT_PANEL,
    payload: {
      isLeftPanelHidden: false,
    },
  };
};

export const hideRightPanel = (): IOperateToggleRightPanel => {
  return {
    type: TOGGLE_RIGHT_PANEL,
    payload: {
      isRightPanelHidden: true,
    },
  };
};
export const showRightPanel = (): IOperateToggleRightPanel => {
  return {
    type: TOGGLE_RIGHT_PANEL,
    payload: {
      isRightPanelHidden: false,
    },
  };
};

export const toggleCommandHistoryCollapse = (
  isCollapsed: boolean
): IOperateToggleCommandHistoryCollapse => {
  return {
    type: TOGGLE_COMMAND_HISTORY_COLLAPSE,
    payload: {
      isCommandHistoryCollapsed: isCollapsed,
    },
  };
};

export const shouldFocusLastHistoryCommand = (
  shouldFocus: boolean
): IHistoryFocusLastSentCommand => {
  return {
    type: HISTORY_FOCUS_LAST_SENT_COMMAND,
    payload: {
      shouldFocusLastSentCommand: shouldFocus,
    },
  };
};
